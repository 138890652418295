.loader {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: left !important;
  }
  
  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }
  
  .loader::before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #ffa80033 0%, #ffa800 100%);
    animation: load012323 .5s infinite linear;
  }
  
  .loader::after {
    width: 85%;
    height: 85%;
    background-color: #222;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes load012323 {
    to {
      transform: rotate(360deg);
    }
  }