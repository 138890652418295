//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;
  background-color: $white;
  &.show {
    top: 100% !important;
  }
}

.dropdown-toggle {
  @include caret(up);
}

.dropdown-menu-end[style] {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: var(--#{$prefix}border-color);
}

[data-bs-theme="dark"]{
  .dropdown-menu{
    background-color: #313a43;

    .dropdown-item:hover{
      background-color: #36404a;
    }
  }
}